import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '@/views/home';
import goodsDetail from '@/views/goodsDetail';
import goodsDetailTest from '@/views/goodsDetailTest';
import cashDesk from '@/views/cashDesk';
import wxCashDesk from '@/views/wxCashDesk';
import wxCashDeskTest from '@/views/wxCashDeskTest';
import transfer from '@/views/transfer'; //跳转微信h5收银台/微信小程序收银台的中转页
import kefu from '@/views/kefu';
import test from '@/views/test';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: goodsDetail
  },
  {
    path: '/goodsDetailTest',
    name: 'goodsDetailTest',
    component: goodsDetailTest
  },
  {
    path: '/cashDesk',
    name: 'cashDesk',
    component: cashDesk
  },
  {
    path: '/wxCashDesk',
    name: 'wxCashDesk',
    component: wxCashDesk
  },
  {
    path: '/wxCashDeskTest',
    name: 'wxCashDeskTest',
    component: wxCashDeskTest
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: transfer
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: kefu
  },
  {
    path: '/test',
    name: 'test',
    component: test
  }
];

const router = new VueRouter({
  mode: 'history',
  //mode: 'hash',
  base: process.env.VUE_APP_URL,
  routes
});

export default router;
