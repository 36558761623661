<template>
  <div class="wrap">
    <div class="price-box">
      <div class="left">
        <p class="text-box">
          <span class="sign flex-item">￥</span>
          <span class="num flex-item">{{ priceObj.OriPrice }}</span>
        </p>
        <p class="vip-money" v-if="priceObj.nowPrice">
          <span class="money">￥{{ priceObj.nowPrice }}</span>
          <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/5.gif" class="img-vip" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priceObj: Object
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {}
};
</script>
<style scoped lang="less">
.price-box {
  width: 100%;
  background: #ffffff;
  padding: 0 16px;
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flex-item {
    flex: 0 0 auto; /* 默认不扩展，不缩小，大小由内容决定 */
  }
  .align-bottom {
    align-self: flex-end; /* 底部对齐 */
  }
  .left {
    display: flex;
    font-size: 15px;
    font-weight: 700;
    align-items: center; /* 底部对齐 */
    .text-box {
      display: flex;
      align-items: center; /* 底部对齐 */
      color: var(--view-priceColor) !important;
      .num {
        font-size: 26px;
      }
    }
    .vip-money {
      display: flex;
      color: #282828;
      margin-left: 7px;
      align-items: center;
      .img-vip {
        width: 36px;
        height: 14px;
        margin-left: 3px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .img-share {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
