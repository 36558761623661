<template>
  <div class="wrap">
    <div class="circle" @click="show = true">温馨提示</div>
    <div class="title-box">
      <p class="title">{{ titleObj.title }}</p>
      <p class="limit-num" v-if="titleObj.limitNum > 0">永久限购{{ titleObj.limitNum }}个</p>
    </div>
    <!-- <div class="agr-box-wrap">
      <div class="agr-box">
        <van-checkbox v-model="checked" @change="changeArg" label-position="right">保护中</van-checkbox>
        <div class="agt-shield-box">
          <span class="tips">温馨提示：</span>
          <span class="arg-link" @click="show = true">《个人信息说明》</span>
        </div>
      </div>
    </div> -->

    <van-popup v-model="show" position="left" :overlay="true" class="arg-content">
      <van-icon name="close" class="i-close" @click="show = false" />
      <div class="content" v-if="show">
        <div v-html="protocol"></div>
      </div>
      <div class="btn-arg-box">
        <span class="btn-arg" @click="submitArg">确认协议</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: { titleObj: Object, protocol: String },
  data() {
    return {
      checked: true,
      show: false
    };
  },
  mounted() {
    const isCheckArg = JSON.parse(sessionStorage.getItem('isCheckArg'));
    if (isCheckArg != undefined) {
      this.checked = isCheckArg;
    } else {
      sessionStorage.setItem('isCheckArg', true);
    }
  },
  methods: {
    changeArg() {
      sessionStorage.setItem('isCheckArg', this.checked);
    },
    submitArg() {
      this.checked = true;
      this.show = false;
    }
  }
};
</script>
<style scoped lang="less">
.circle {
  z-index: 9999;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0; /* 设置距离右边的距离 */
  top: 120px; /* 设置距离顶部的距离 */
  width: 30px; /* 圆形的宽度 */
  height: 80px; /* 圆形的高度 */
  border-radius: 5px;
  background-color: #f8f8f8; /* 浅色背景，半透明 */
  color: #4a90e2; /* 浅色文字 */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
  font-family: Arial, sans-serif;
}
.arg-content {
  overflow: hidden;
  min-height: 500px;
  max-height: 800px;
  position: relative;
  .content {
    padding: 35px 10px;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 120px;
    line-height: 1.6;
    text-align: left;
    color: #7b7b7b;
    /deep/ .ql-align-center {
      text-align: center;
      font-size: 13px;
    }
    /deep/ h4 {
      padding: 10px 0;
      font-size: 13px;
    }
    /deep/ p {
      font-size: 13px;
    }
  }
  .btn-arg-box {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    background: #ffffff;
    .btn-arg {
      margin: 10px 0;
      width: 300px;
      text-align: center;
      line-height: 41px;
      color: #fff;
      font-size: 15px;
      border-radius: 20px;
      background-color: var(--view-theme);
    }
  }

  .i-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    background: #ffffff;
  }
  ::v-deep .van-icon-close {
    font-size: 20px;
  }
}
.agr-box-wrap {
  width: 100%;
  padding: 0 16px;
  .tips {
    font-size: 12px;
    color: gray;
  }
  .arg-link {
    color: #a8bddb;
    font-size: 12px;
  }
}
.agr-box {
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .agt-shield-box {
    display: flex;
    align-items: center;
    .agt-txt {
      color: #15b911;
      font-size: 13px;
    }
  }
}
::v-deep .van-icon-success {
  font-size: 13px;
  display: flex;
  align-items: center;
}
::v-deep .van-checkbox__icon--checked .van-icon {
  color: #fff;
  background-color: #15b911;
  border-color: #15b911;
  font-size: 13px;
  display: flex;
  align-items: center;
}
::v-deep .van-checkbox__label {
  font-size: 13px;
  color: gray;
}
.title-box {
  padding: 12px 16px 12px 16px;
  background: #ffffff;
  .title {
    font-size: 16px;
    word-break: break-all;
    min-height: 24px;
    text-align: left;
    line-height: 20px;
    color: #333;
    font-weight: 700;
  }
  .limit-num {
    text-align: left;
    font-size: 8px;
    margin: 5px 0;
    color: red;
  }
}
</style>
