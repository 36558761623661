<template>
  <div class="footer-box">
    <!-- <div class="agr-box-wrap">
      <div class="agr-box">
        <div class="agt-shield-box">
          <span class="tips">温馨提示：</span>
          <span class="arg-link" @click="show = true">《个人信息说明》</span>
        </div>
        <van-checkbox v-model="checked" @change="changeArg" label-position="left">保护中</van-checkbox>
      </div>
    </div> -->
    <div class="btn-box">
      <span class="btn buy" @click="$emit('pay')">确认支付</span>
    </div>

    <!-- <van-popup v-model="show" position="bottom" :overlay="true" class="arg-content">
      <van-icon name="close" class="i-close" @click="show = false" />
      <div class="content" v-if="show">
        <div v-html="protocol"></div>
      </div>
      <div class="btn-arg-box">
        <span class="btn-arg" @click="submitArg">确认协议</span>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
export default {
  //props: { protocol: String },
  data() {
    return {
      checked: true,
      show: false
    };
  },
  mounted() {
    // const isCheckArg = JSON.parse(sessionStorage.getItem('isCheckArg'));
    // if (isCheckArg != undefined) {
    //   this.checked = isCheckArg;
    // } else {
    //   sessionStorage.setItem('isCheckArg', true);
    // }
  },
  methods: {
    // changeArg() {
    //   sessionStorage.setItem('isCheckArg', this.checked);
    // },
    // submitArg() {
    //   this.checked = true;
    //   this.show = false;
    // }
  }
};
</script>
<style scoped lang="less">
.footer-box {
  // .arg-content {
  //   min-height: 500px;
  //   max-height: 800px;
  //   position: relative;
  //   .content {
  //     padding: 35px 10px;
  //     height: 500px;
  //     overflow: hidden;
  //     overflow-y: auto;
  //     padding-bottom: 68px;
  //     line-height: 1.6;
  //     text-align: left;
  //     color: #7b7b7b;
  //     /deep/ .ql-align-center {
  //       text-align: center;
  //     }
  //     /deep/ h4 {
  //       padding: 10px 0;
  //     }
  //   }
  //   .btn-arg-box {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     display: flex;
  //     width: 100%;
  //     justify-content: center;
  //     background: #ffffff;
  //     .btn-arg {
  //       margin: 10px 0;
  //       width: 300px;
  //       text-align: center;
  //       line-height: 41px;
  //       color: #fff;
  //       font-size: 15px;
  //       border-radius: 20px;
  //       background-color: var(--view-theme);
  //     }
  //   }

  //   .i-close {
  //     position: absolute;
  //     right: 10px;
  //     top: 10px;
  //     z-index: 9999;
  //     background: #ffffff;
  //   }
  //   ::v-deep .van-icon-close {
  //     font-size: 20px;
  //   }
  // }
  // .agr-box-wrap {
  //   width: 80%;
  //   padding-bottom: 8px;
  //   .tips {
  //     font-size: 12px;
  //     color: gray;
  //   }
  //   .arg-link {
  //     color: #a8bddb;
  //     font-size: 12px;
  //   }
  // }
  // .agr-box {
  //   padding-bottom: 5px;
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   .agt-shield-box {
  //     display: flex;
  //     align-items: center;
  //     .agt-txt {
  //       color: #15b911;
  //       font-size: 13px;
  //     }
  //   }
  //   ::v-deep .van-icon-success {
  //     font-size: 13px;
  //     display: flex;
  //     align-items: center;
  //   }
  //   ::v-deep .van-checkbox__icon--checked .van-icon {
  //     color: #fff;
  //     background-color: #15b911;
  //     border-color: #15b911;
  //     font-size: 12px;
  //     display: flex;
  //     align-items: center;
  //   }
  //   ::v-deep .van-checkbox__label {
  //     font-size: 13px;
  //     color: gray;
  //   }
  // }
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px 5px 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  .item {
    font-size: 9px;
    color: #666;
    .label {
      text-align: center;
    }
    .img-icon {
      width: 23px;
      height: 23px;
    }
  }
  .btn-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 41px;
    justify-content: center;
    .btn {
      width: 80%;
      text-align: center;
      line-height: 41px;
      color: #fff;
      font-size: 15px;
    }
    .joinCart {
      border-radius: 27px 0 0 27px;
      background-color: var(--view-bntColor);
    }
    .buy {
      border-radius: 20px;
      background-color: var(--view-theme);
    }
  }
}
</style>
